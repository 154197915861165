import {Controller} from 'stimulus'

export default class extends Controller {
  static targets = ['studentType', 'parentType', 'studentFields']

  connect () {
    this.change()
  }

  change () {
    const displayStudentFields = this.studentTypeTarget.checked ||
      this.parentTypeTarget.checked
    this.studentFieldsTarget.style.display = displayStudentFields ? 'initial' : 'none'
  }
}
